import { CrudeTab } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
const crudeTabConfig = (): CrudeTab[] => {
  return [
    {
      entity: 'License',
      columns: [
        {
          name: 'name',
          hideInTableView: false,
          pinned: true,
          columnWidth: 200,
        },
        {
          name: 'licenseKey',
          hideInTableView: false,
          columnWidth: 200,
        },
        {
          name: 'product',
          hideInTableView: false,
        },
        {
          name: 'productVersion',
          hideInTableView: false,
        },
        {
          name: 'notBefore',
          hideInTableView: false,
        },
        {
          name: 'expires',
          hideInTableView: false,
        },
        {
          name: 'lifetimeLicenseInDays',
          hideInTableView: false,
        },
        {
          name: 'lifetimeInstallationInDays',
          hideInTableView: false,
        },
        {
          name: 'maxInstallations',
          hideInTableView: true,
        },
        {
          name: 'schemaVersion',
          hideInTableView: true,
        },
        {
          name: 'licenseVersion',
          hideInTableView: true,
        },
        {
          name: 'sourceType',
          hideInTableView: true,
        },
        {
          name: 'devWhitelist',
          hideInTableView: true,
        },
        {
          name: 'prodWhitelist',
          hideInTableView: true,
        },
      ],
    },
    {
      entity: 'Installation',
      columns: [
        {
          name: 'name',
        },
        {
          name: 'installationKey',
        },
        {
          name: 'customer',
        },
        {
          name: 'createdAt',
        },
      ],
    },
    {
      entity: 'Product',
    },
    {
      entity: 'Feature',
    },
    {
      entity: 'Customer',
    },
    {
      entity: 'User',
    },
    {
      entity: 'ErrorLogs',
      columns: [
        {
          name: 'error',
          columnWidth: 300,
          pinned: true,
        },
        {
          name: 'licenseKey',
        },
        {
          name: 'installationName',
        },
        {
          name: 'installationKey',
        },
        {
          name: 'productKey',
        },
        {
          name: 'domain',
        },
        {
          name: 'statusCode',
        },
        {
          name: 'createdAt',
          pinned: true,
          sort: 'desc',
        },
      ],
    },
    {
      entity: 'LicenseHasFeatures',
      hideEntity: true,
    },
    {
      entity: 'LicenseHasInstallation',
      hideEntity: true,
    },
  ]
}
export default crudeTabConfig
